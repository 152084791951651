<template>
  <div>
    <p class="text-2xl mb-6">Liste des Sites</p>
    <v-col cols="12" class="d-flex flex-row-reverse">
      <v-btn color="primary" link :to="{ name: 'admin-edit-site' }">
        <v-icon class="mr-3">{{ icons.mdiPlusBox }}</v-icon
        >Créer un nouveau site</v-btn
      >
    </v-col>
    <v-col cols="12"> <sites-table></sites-table> </v-col>
  </div>
</template>

<script>
import { mdiPlusBox } from "@mdi/js";
import SitesTable from "@/components/admin/site/tables/SitesTable";

export default {
  name: "SiteList",
  components: {
    SitesTable,
  },
  created() {},
  data() {
    return {
      icons: {
        mdiPlusBox,
      },
    };
  },
  methods: {},
  watch: {},
};
</script>

<style scoped></style>
